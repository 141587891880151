import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../utils/normalize.css"
import "../utils/css/screen.css"

const SoftwarePage = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout title={siteTitle}>
      <SEO
        title="Software"
        keywords={[
          "software",
          "computer science",
          "steve jobs",
          "art",
          "technology",
        ]}
      />

      <article className="post-content page-template no-image">
        <div className="post-content-body">
          <h2>Software</h2>
          <p>
            I have been fascinated by computers and software since I saw my
            first computer at the age of 5. Even in my most distracted periods
            in my teens, I could be hypnotized infront of a computer for hours.
            I have studied Computer Science in college and pursued a
            professional career as a software engineer afterwards for I believe
            it is the ultimate medium for human expression and impact in the
            21st century.
          </p>
          <p>
            Below are some of my more professional experience building software.
          </p>
        </div>
        {/* <h2>Experience</h2> */}
        <h4>Testcode</h4>
        <div className="row">
          <p className="col-9">
            At Testcode we are building new social consumer software. More coming soon.
          </p>
        </div>

        <h4>VTS</h4>
        <div className="row">
          <p className="col-9">
            At VTS I help build a marketplace where companies will find and
            lease spaces to work. Marketplaces are one of the key components of
            the human civilization that accelerated collaboration and progress.
            By learning to kickstart a marketplace from scratch I get to
            understand the dynamics of marketplaces and that is immensely
            motivating to me.
          </p>
          <Img
            className="col-3"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "top center",
            }}
            fluid={data.vtsImage.childImageSharp.fluid}
          />
        </div>
        <h4>Chartbeat</h4>
        <div className="row">
          <p className="col-9">
            Chartbeat is a media software company. I started my journey at
            Chartbeat as a software engineering intern after my junior year in
            college and continued after graduation. During my 5 years and a
            summer at Chartbeat I really enjoyed understanding and learning
            about the media industry while building software that's used by
            every major newsroom around the globe.
          </p>
          <Img
            className="col-3"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "top center",
            }}
            fluid={data.chartbeatImage.childImageSharp.fluid}
          />
        </div>
        <h4>Carnegie Mellon University</h4>
        <div className="row">
          <p className="col-9">
            After my sophomore year in college I spent my summer at Carnegie
            Mellon University doing a research internship. During this
            internship I developed a mid-layer api that helped translate visual
            input into code for a complex robotics programming framework so that
            kids could easily learn to program robots.
          </p>
          <Img
            className="col-3"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "top center",
            }}
            fluid={data.cmuImage.childImageSharp.fluid}
          />
        </div>
        <h4>Opensea</h4>
        <div className="row">
          <p className="col-9">
            During my junior year in college, there was a wave of anonymous
            social apps. Secret, Whisper, YikYak and more were taking the tech
            world by storm. I built and launched Opensea. Instead of using
            location to group users into feeds we used their college email
            extensions. This way we aimed to capture the users during spring
            break and summer vacation while other apps became virtually useless.
            We had a quick hit in various college campuses however I decided to
            shut it down as I saw misuse, bullying and legal issues happening to
            other apps. Anonymous social networks didn't turn out to be an idea
            that caught on, but this was a great and fun experience for me from
            all technical, product and strategy aspects.
          </p>
          <Img
            className="col-3"
            imgStyle={{
              objectFit: "contain",
              objectPosition: "top center",
            }}
            fluid={data.openseaImage.childImageSharp.fluid}
          />
        </div>
      </article>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    openseaImage: file(relativePath: { eq: "software/opensea.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    chartbeatImage: file(relativePath: { eq: "software/chartbeat.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    vtsImage: file(relativePath: { eq: "software/vts.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cmuImage: file(relativePath: { eq: "software/cmu.png" }) {
      childImageSharp {
        fluid(maxWidth: 1360) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <SoftwarePage location={props.location} data={data} {...props} />
    )}
  />
)
